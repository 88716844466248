import { Injectable } from '@angular/core';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';


@Injectable({
  providedIn: 'root'
})
export class CallNumberService {

  constructor(
    private callNumber: CallNumber,
  ) { }

  call(number) {
    return this.callNumber.callNumber(number, true)
  }

  
}
