// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ng-lottie {
  height: 100%;
  width: auto;
}

.close-modal {
  position: absolute;
  color: white;
  top: 10px;
  right: 50px;
  width: 10px;
  height: 40px;
  z-index: 2;
  text-align: center;
}
.close-modal ion-button {
  font-size: 22px;
}

.ios .close-modal {
  top: 30px;
  right: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/modales/modal-timeline-pasos/modal-timeline-pasos.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;AACJ;;AAEA;EACI,kBAAA;EACA,YAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,kBAAA;AACJ;AAAI;EACI,eAAA;AAER;;AAGI;EACI,SAAA;EACA,WAAA;AAAR","sourcesContent":["ng-lottie{\n    height: 100%;\n    width: auto;\n}\n\n.close-modal {\n    position: absolute;\n    color: white;\n    top: 10px;\n    right: 50px;\n    width: 10px;\n    height: 40px;\n    z-index: 2;\n    text-align: center;\n    ion-button{\n        font-size: 22px;\n    }\n}\n\n.ios{\n    .close-modal{\n        top: 30px;\n        right: 50px;\n        \n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
