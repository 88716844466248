import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertasService } from 'src/app/servicios/alertas/alertas.service';
import { PhotoService } from 'src/app/servicios/file/photo.service';

@Component({
  selector: 'app-form-agregar-imagen',
  templateUrl: './form-agregar-imagen.component.html',
  styleUrls: ['./form-agregar-imagen.component.scss'],
})
export class FormAgregarImagenComponent implements OnInit {

  @Input() images: Array<any> = [];
  @Input() image: any;
  @Input() nImage: number = 1;
  @Input() mostrarImg: boolean = true;

  @Output() onImagen = new EventEmitter();
  @Output() onEliminarFichero = new EventEmitter();

  constructor(
    private photoService: PhotoService,
    private translate: TranslateService,
    private alertasService: AlertasService,
  ) { }

  ngOnInit() { 
    if (!Array.isArray(this.images)) this.images = [];
  }
  
  onAbirFolder() {
    if (!this.isValidMaxImgs()) return;
    if (this.nImage > 1) {
      let nI = this.nImage - this.images.length;
      this.photoService.multiplePicture(nI).then((imgs) => {
        if (imgs.length > 0) {
          this.images = [...this.images, ...imgs];
          this.onImagen.emit(this.images);
        }
      })
    } else {
      this.photoService.takePicture("PHOTOS").then((img) => {
        this.image = img;
        this.onImagen.emit(img);
      })
    }
  }
  
  onAbirCamara() {
    if (!this.isValidMaxImgs()) return;
    this.photoService.takePicture("CAMERA").then((img) => {
      if (this.nImage > 1) { 
        this.images.push(img);
        this.onImagen.emit(this.images);
      } else {
        this.image = img;
        this.onImagen.emit(img);
      }
    })
  }

  isValidMaxImgs() {
    if (this.nImage > 1 && this.images.length >= this.nImage) {
      if (this.nImage > 1) {
        this.alertasService.presentToast(this.translate.instant("generales.error-limite-imgs",{limite: this.nImage}),3000);
      } else {
        this.alertasService.presentToast(this.translate.instant("generales.error-limite-img"),3000);
      }
      return false;
    }
    return true;
  }

  removerImgs(i) {
    this.images.splice(i, 1)
    this.onEliminarFichero.emit(i);
  }
  
  removerImg() {
    this.image = null;
    this.onEliminarFichero.emit(0);
  }

}
