import { IAyudaTecnica } from '../interfaces/IAyudaTecnica';
import { Fichero } from './fichero.model';

export class AyudaTecnica implements IAyudaTecnica {
  texto_informativo: string;
  documentos_generales: Array<Fichero>;
  documentos_especificos: Array<Fichero>;

  constructor(data: AyudaTecnica) {
    if (data.texto_informativo !== undefined) this.texto_informativo = data.texto_informativo;
    if (data.documentos_generales !== undefined) this.documentos_generales = data.documentos_generales.map((i: Fichero) => new Fichero(i));
    if (data.documentos_especificos !== undefined) this.documentos_especificos = data.documentos_especificos.map((i: Fichero) => new Fichero(i));
    return this;
  }

}
