import { format, formatISO, parseISO } from 'date-fns';
import { EstadosClientes } from '../enums/estados-clientes.enum';
import { SegClientesListado } from '../enums/seg-clientes-listado.enum';
import { TiposDocumentos } from '../enums/tipos-documentos.enum';
import { ICliente } from '../interfaces/ICliente';
import { IProcesoPedido } from '../interfaces/IProcesoPedido';
import { Fichero } from './fichero.model';

export class Cliente implements ICliente {
  id: number;
  dni: string;
  nombre: string;
  apellidos: string;
  ref: string;
  fecha_visita: string;
  fecha_instalacion_inicio: string;
  fecha_informefininstalacion: string;
  fecha_emisioninformevisita: string;
  fecha_instalacion_planificada: string;
  fecha_concesionlicencia: string;
  fecha_desplazamientoincidencia: string;
  direccion: string;
  municipio: string;
  provincia: string;
  lat: string;
  lon: string;
  informe_visita: boolean;
  informe_instalacion: boolean;
  valor: number;
  estadoslug: EstadosClientes;
  estadolabel: string;
  proceso: Array<IProcesoPedido>;
  equipos: Array<{
    cantidad: string;
    modelo: string;
    equipo_id: string;
    clave_tipo: string;
    nombre_tipo: string;
    marca: string;
  }>;
  disposiciones_imagenes: Array<string>;
  disposicion: boolean;
  tipo_instalacion: string;
  tipo_cliente: string;
  tipo_propiedad: string;
  grua: string;
  seleccionador: boolean;
  comentario_instalador: string;
  distribuidoras: Array<{
    id: number,
    nombre: string,
  }> = [];
  puede_agendar: boolean;
  desplazamientoincidencia: boolean;
  historial_cliente: Array<{
    nombre: string;
    fecha: string;
    estado: string;
  }>;
  telefono: string;
  puede_form_visita: boolean;
  puede_form_baterias: boolean;
  puede_form_cargadores: boolean;
  hace_incidencia: boolean;
  hace_instalacion: boolean;
  hace_visita: boolean;
  hace_legalizacion: boolean;
  informes_y_fotos: Array<{nombre: string, ficheros: Array<Fichero>}>
  clave_ente: string;
  porcentaje: {
    color: string;
    porcentaje: number;
  };
  presupuesto_firmado: boolean;

  constructor(data: Cliente) {
		if(data.id !== undefined) this.id = data.id;
		if(data.dni !== undefined) this.dni = data.dni;
		if(data.nombre !== undefined) this.nombre = data.nombre;
		if(data.apellidos !== undefined) this.apellidos = data.apellidos;
		if(data.ref !== undefined) this.ref = data.ref;
		if(data.fecha_visita) this.fecha_visita = formatISO(parseISO(data.fecha_visita.replace(" ", "T")));
		if(data.fecha_instalacion_inicio) this.fecha_instalacion_inicio = formatISO(parseISO(data.fecha_instalacion_inicio.replace(" ", "T")));
		if(data.fecha_informefininstalacion) this.fecha_informefininstalacion = formatISO(parseISO(data.fecha_informefininstalacion.replace(" ", "T")));
    if (data.fecha_emisioninformevisita) this.fecha_emisioninformevisita = formatISO(parseISO(data.fecha_emisioninformevisita.replace(" ", "T")));
    if (data.fecha_instalacion_planificada && data.fecha_instalacion_planificada != "0000-00-00") this.fecha_instalacion_planificada = formatISO(parseISO(data.fecha_instalacion_planificada.replace(" ", "T")))
		if(data.fecha_concesionlicencia) this.fecha_concesionlicencia = formatISO(parseISO(data.fecha_concesionlicencia.replace(" ", "T")));
		if(data.fecha_desplazamientoincidencia) this.fecha_desplazamientoincidencia = formatISO(parseISO(data.fecha_desplazamientoincidencia.replace(" ", "T")));
		if(data.direccion !== undefined) this.direccion = data.direccion;
		if(data.municipio !== undefined) this.municipio = data.municipio;
		if(data.provincia !== undefined) this.provincia = data.provincia;
		if(data.lat !== undefined) this.lat = data.lat;
		if(data.lon !== undefined) this.lon = data.lon;
		if(data.informe_visita !== undefined) this.informe_visita = data.informe_visita;
		if(data.informe_instalacion !== undefined) this.informe_instalacion = data.informe_instalacion;
		if(data.valor !== undefined) this.valor = data.valor;
		if(data.estadoslug !== undefined) this.estadoslug = data.estadoslug;
		if(data.estadolabel !== undefined) this.estadolabel = data.estadolabel;
    if(data.equipos !== undefined) this.equipos = data.equipos;
		if(data.disposiciones_imagenes !== undefined) this.disposiciones_imagenes = data.disposiciones_imagenes;
		if(data.disposicion !== undefined) this.disposicion = data.disposicion;
		if(data.tipo_instalacion !== undefined) this.tipo_instalacion = data.tipo_instalacion;
		if(data.tipo_cliente !== undefined) this.tipo_cliente = data.tipo_cliente;
		if(data.tipo_propiedad !== undefined) this.tipo_propiedad = data.tipo_propiedad;
		if(data.grua !== undefined) this.grua = data.grua;
		if(data.seleccionador !== undefined) this.seleccionador = data.seleccionador;
		if(data.comentario_instalador !== undefined) this.comentario_instalador = data.comentario_instalador;
		if(data.distribuidoras !== undefined) this.distribuidoras = data.distribuidoras;
		if(data.puede_agendar !== undefined) this.puede_agendar = data.puede_agendar;
		if(data.desplazamientoincidencia !== undefined) this.desplazamientoincidencia = data.desplazamientoincidencia;
    if (data.historial_cliente !== undefined) this.historial_cliente = data.historial_cliente;
    if (data.proceso) {
      this.proceso = data.proceso.map((item: any) => {
        if (item?.fecha) {
          item.fecha = formatISO(parseISO(item.fecha.replace(" ", "T")))
        };
        return item;
      });
    }
		if(data.telefono !== undefined) this.telefono = data.telefono;
		if(data.puede_form_baterias !== undefined) this.puede_form_baterias = data.puede_form_baterias;
		if(data.puede_form_cargadores !== undefined) this.puede_form_cargadores = data.puede_form_cargadores;
		if(data.puede_form_visita !== undefined) this.puede_form_visita = data.puede_form_visita;
		if(data.hace_incidencia !== undefined) this.hace_incidencia = data.hace_incidencia;
		if(data.hace_instalacion !== undefined) this.hace_instalacion = data.hace_instalacion;
		if(data.hace_visita !== undefined) this.hace_visita = data.hace_visita;
    if (data.hace_legalizacion !== undefined) this.hace_legalizacion = data.hace_legalizacion;
    /* if (data.imagenes_fin_instalacion !== undefined) this.imagenes_fin_instalacion = data.imagenes_fin_instalacion.map((i: Fichero) => new Fichero(i));
    if (data.imagenes_visita !== undefined) this.imagenes_visita = data.imagenes_visita.map((i: Fichero) => new Fichero(i));
    if (data.imagenes_legalizacion !== undefined) this.imagenes_legalizacion = data.imagenes_legalizacion.map((i: Fichero) => new Fichero(i)); */
    if (data.informes_y_fotos !== undefined) this.informes_y_fotos = data.informes_y_fotos;
    if (data.clave_ente !== undefined) this.clave_ente = data.clave_ente;
		if(data.porcentaje !== undefined) this.porcentaje = data.porcentaje;
		if(data.presupuesto_firmado !== undefined) this.presupuesto_firmado = data.presupuesto_firmado;
    return this;
  }

  getNombreCompl() {
    return this.nombre + " " + this.apellidos;
  }

  getFechaEstado(segm = "") {
    if (!segm) segm = this.estadoslug;
    if ((segm == SegClientesListado.instalacion || segm == EstadosClientes.planificacion)) return this.fecha_instalacion_inicio;
    if (segm == SegClientesListado.visita || segm == EstadosClientes.planificacion) return this.fecha_visita;
    return "";
  }

  getColor() {
    if (this.esIncidencia()) {
      return SegClientesListado.incidencia;
    }
    return this.estadoslug;
  }

  getUltimaFecha() {
    return this.fecha_instalacion_inicio || this.fecha_visita;
  }

  getUltimoEstado() {
    if (this.fecha_instalacion_inicio) return EstadosClientes.instalacion;
    return EstadosClientes.visita;
  }

  esVisita() {
    return (!this.fecha_emisioninformevisita && this.hace_visita)
  }

  esInstalacion() {
    return ((!this.fecha_informefininstalacion && this.hace_instalacion) || (this.hace_legalizacion))
  }

  esIncidencia() {
    return (this.desplazamientoincidencia && this.hace_instalacion)
  }

  esAgendarVisita(){
    return (!this.fecha_visita && this.hace_visita && this.estadoslug == EstadosClientes.visita)
  }

  esInformeVisita(){
    return ((this.fecha_visita && format(new Date(this.fecha_visita), "Y-M-d") <= format(new Date(), "Y-M-d")) && !this.fecha_emisioninformevisita && this.hace_visita && this.estadoslug == EstadosClientes.visita)
  }

  esAgendarInstalacion(){
    return ((this.estadoslug == EstadosClientes.planificacion && this.fecha_concesionlicencia && this.fecha_instalacion_planificada && !this.fecha_instalacion_inicio && this.hace_instalacion) )
  }

  esInformeInstalacion() {
    return ((this.fecha_instalacion_inicio && format(new Date(this.fecha_instalacion_inicio), "Y-M-d") <= format(new Date(), "Y-M-d")) && this.estadoslug == EstadosClientes.instalacion && !this.fecha_informefininstalacion && this.hace_instalacion);
  }

  esIncidenciaConDesplazamiento() {
    return (this.esIncidencia() && ((this.fecha_desplazamientoincidencia && format(new Date(this.fecha_desplazamientoincidencia), "Y-M-d") >= format(new Date(), "Y-M-d"))));
  }

  esLegalizacion() {
    return this.estadoslug == EstadosClientes.legalizacion;
  }

  tieneIncidencia() {
    return "incidencia";
  }

  esSoty() {
    return this.clave_ente == 'soty';
  }

  esTotal() {
    return this.clave_ente == 'total';
  }

  esMediaMarkt() {
    return this.clave_ente == 'mediamarkt';
  }

  getDocumentoPorTipo(tipo: TiposDocumentos) {
    let docs = this.informes_y_fotos;
    let doc;
    docs.forEach(element => {
      if (doc) return;
      element.ficheros.sort((a, b) =>  (a.fecha < b.fecha) ? 0 : -1)
      doc = element.ficheros.find(d => d.visibilidad == tipo);
    });
    return doc;
  }

  getEquipoXTipo(tipo) {
    let res =  this.equipos.filter(e => {
      if (tipo == "equipo") return !["placa", "estructura"].includes(e.clave_tipo);
      return e.clave_tipo == tipo;
    })
    return res;
  }

}
