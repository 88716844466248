import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-modal-guardo-correctamente',
  templateUrl: './modal-guardo-correctamente.component.html',
  styleUrls: ['./modal-guardo-correctamente.component.scss'],
})
export class ModalGuardoCorrectamenteComponent implements OnInit {

  options: AnimationOptions = {
    path: '/assets/animaciones/guardo-correctamente/data.json',
  };
  constructor(
    private modalContrller: ModalController,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.close()
    }, 2000);
  }

  close() {
    this.modalContrller.dismiss();
  }

}
