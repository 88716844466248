// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-msg-error {
  height: 25px;
  --background: transparent;
}
.form-msg-error .form-content-error {
  position: absolute;
  top: -2px;
  color: var(--ion-color-danger-shade);
}
.form-msg-error .form-content-error ion-icon {
  font-size: 16px;
  margin: 0;
  position: absolute;
  top: 10px;
  transform: translateY(-50%);
}
.form-msg-error .form-content-error span {
  font-size: 11px;
  margin-left: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/formularios/form-errors/form-errors.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,yBAAA;AACJ;AAAI;EACI,kBAAA;EACA,SAAA;EACA,oCAAA;AAER;AADQ;EACI,eAAA;EACA,SAAA;EACA,kBAAA;EACA,SAAA;EAEA,2BAAA;AAGZ;AADQ;EACI,eAAA;EACA,iBAAA;AAGZ","sourcesContent":[".form-msg-error {\n    height: 25px;\n    --background: transparent;\n    .form-content-error{\n        position: absolute;\n        top: -2px;\n        color: var(--ion-color-danger-shade);\n        ion-icon{\n            font-size: 16px;\n            margin: 0;\n            position: absolute;\n            top: 10px;\n            -ms-transform: translateY(-50%);\n            transform: translateY(-50%);\n        }\n        span{\n            font-size: 11px;\n            margin-left: 20px;\n        }\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
