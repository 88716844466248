import { IUsuario } from '../interfaces/IUsuario';

export class Usuario implements IUsuario {
  id: number;
  nombre: string;
  apellidos: string;
  email: string;
  onesignal_player: string;
  dni: string;
  telefono: string;
  municipio: string;
  provincia: string;
  lat: string;
  lon: string;
  direccion: string;
  app_token: string;

  constructor(data: Usuario) {
		if(data.id !== undefined) this.id = data.id;
		if(data.nombre !== undefined) this.nombre = data.nombre;
		if(data.apellidos !== undefined) this.apellidos = data.apellidos;
		if(data.email !== undefined) this.email = data.email;
		if(data.dni !== undefined) this.dni = data.dni;
    if (data.telefono !== undefined) this.telefono = data.telefono;
    if(data.direccion !== undefined) this.direccion = data.direccion;
		if(data.municipio !== undefined) this.municipio = data.municipio;
		if(data.provincia !== undefined) this.provincia = data.provincia;
		if(data.lat !== undefined) this.lat = data.lat;
    if (data.lon !== undefined) this.lon = data.lon;
		if(data.direccion !== undefined) this.direccion = data.direccion;
		if(data.app_token !== undefined) this.app_token = data.app_token;
    return this;
  }

  getNombreCompl() {
    return this.nombre + " " + this.apellidos;
  }

}