import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImgErrorDirective } from './img-error/img-error.directive';
//import { DatetimeTextDirective } from './datetime-text/datetime-text.directive';
//import { SwipeDirective } from './swipe/swipe.directive';



@NgModule({
  declarations: [
    ImgErrorDirective,
    //DatetimeTextDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ImgErrorDirective,
    //DatetimeTextDirective,
  ]
})
export class CommonDirectivesModule { }
