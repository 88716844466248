import { Injectable } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Cliente } from 'src/app/modelos/cliente.model';
import { Usuario } from 'src/app/modelos/usuario.model';
import { environment } from 'src/environments/environment';
import { NavegacionService } from '../navegacion/navegacion.service';
import { OnesignalService } from '../onesignal/onesignal.service';
import { ClienteService } from '../singleton/cliente.service';
import { UsuarioService } from '../singleton/usuario.service';
import { StorageService } from '../storage/storage.service';
import { FirebaseAnalyticsService } from '../firebase-analytics/firebase-analytics.service';
import { AyudaTecnica } from 'src/app/modelos/ayuda-tecnica.model';


@Injectable({
  providedIn: 'root'
})
export class AutenticacionService {
  auth_state = false;

  constructor(
    private storageService: StorageService,
    private usuService: UsuarioService,
    private menuController: MenuController,
    private clienteService: ClienteService,
    private navegacionService: NavegacionService,
    private onesignalService: OnesignalService,
    private firebaseAnalyticsService: FirebaseAnalyticsService
    ) {
  }

  async checkToken(): Promise<boolean>{
    if (this.usuService?.usu?.app_token) {
      this.setOnesignalUser();
      this.setFirebaseUser();
      return true;
    }
    let usuarioStorage = await this.storageService.get(environment.usu_storage)
    let clientesStorage = await this.storageService.get(environment.cli_storage)
    if (!usuarioStorage?.usu?.app_token) return this.auth_state = false;
    this.usuService.usu = new Usuario(usuarioStorage.usu);
    this.usuService.ayudatecnica = new AyudaTecnica(usuarioStorage.ayudatecnica);
    if (clientesStorage) {
      this.clienteService.clientes = clientesStorage.clientes.map((cliente: Cliente) => new Cliente(cliente));
    }
    this.setOnesignalUser();
    this.setFirebaseUser();
    return this.auth_state = true;
  }

  setOnesignalUser() {
    if (this.usuService?.usu?.id) {
      this.onesignalService.removeExternalUserId()
      this.onesignalService.setUserId(this.usuService.usu.id);
    }
  }

  setFirebaseUser() {
    if (this.usuService?.usu?.id) {
      this.firebaseAnalyticsService.setUserProperty(this.usuService.usu.id, this.usuService.usu.email);
    }
  }

  async isAuth() {
    const userStorage = await this.storageService.get(environment.usu_storage);
    if (userStorage) {
        return userStorage;
    }
    return false;
  }

  logout() {
    this.onesignalService.removeExternalUserId();
    this.menuController.enable(false, "first");
    this.clienteService.clientes = [];
    this.usuService.usu = null;
    this.storageService.remove(environment.usu_storage);
    this.storageService.remove(environment.cli_storage);
    this.auth_state = false;
    this.navegacionService.goTo("login");
  }

  isAuthenticated() {
    return this.auth_state;
  }

}
