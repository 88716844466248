// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-modal {
  pointer-events: all;
  color: white;
  text-align: center;
  font-size: 22px;
  width: 100%;
  height: 100%;
  /* ion-button{
  } */
}

.ios .close-modal {
  top: 30px;
  right: 50px;
}

.sub-button {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100px;
  height: 70px;
  pointer-events: none;
  z-index: 4;
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/modales/modal-video-ayuda/modal-video-ayuda.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA;KAAA;AAEJ;;AAGI;EACI,SAAA;EACA,WAAA;AAAR;;AAKA;EACI,kBAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,YAAA;EACA,oBAAA;EACA,UAAA;AAFJ;;AAKA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,gBAAA;AAFJ","sourcesContent":[".close-modal {\n    pointer-events: all;\n    color: white;\n    text-align: center;\n    font-size: 22px;\n    width: 100%;\n    height: 100%;\n    /* ion-button{\n    } */\n}\n\n.ios{\n    .close-modal{\n        top: 30px;\n        right: 50px;\n        \n    }\n}\n\n.sub-button{\n    position: absolute;\n    top: 0px;\n    right: 0px;\n    width: 100px;\n    height: 70px;\n    pointer-events: none;\n    z-index: 4;\n}\n\n.iframe{\n    position: absolute; \n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 1;\n    overflow:hidden;\n    \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
