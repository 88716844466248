// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card-header {
  padding: 24px 20px 10px;
}
ion-card-header ion-card-subtitle {
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 1.1px;
}
ion-card-header .subtitulo1 {
  color: #222428;
}
ion-card-header .subtitulo2 {
  color: #989AA2;
  margin-top: 4px;
}
ion-card-header ion-card-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.6px;
  color: #000000;
  margin-top: 10px;
}

ion-card-content {
  padding: 0 20px 24px;
}
ion-card-content p {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.7px;
  color: #92949C;
}
ion-card-content a {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.7px;
  color: #92949C;
}

ion-card ion-button {
  position: absolute;
  right: 0;
  top: 0;
}
ion-card ion-button::part(native) {
  font-size: 20px;
  padding: 17px 5px;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/modales/modal-informativa/modal-informativa.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;AACJ;AACE;EACE,cAAA;AACJ;AAEE;EACE,cAAA;EACA,eAAA;AAAJ;AAGE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;EACA,cAAA;EACA,gBAAA;AADJ;;AAKA;EACE,oBAAA;AAFF;AAIE;EACE,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,cAAA;AAFJ;AAKE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;EACA,cAAA;AAHJ;;AAQE;EACE,kBAAA;EACA,QAAA;EACA,MAAA;AALJ;AAQE;EACE,eAAA;EACA,iBAAA;EACA,YAAA;AANJ","sourcesContent":["ion-card-header{\n  padding: 24px 20px 10px;\n\n  ion-card-subtitle{\n    font-weight: 700;\n    font-size: 13px;\n    line-height: 16px;\n    letter-spacing: 1.1px;\n  }\n  .subtitulo1{\n    color: #222428;\n  }\n\n  .subtitulo2{\n    color: #989AA2;\n    margin-top:4px;\n  }\n\n  ion-card-title{\n    font-weight: 700;\n    font-size: 28px;\n    line-height: 33px;\n    letter-spacing: 0.6px;\n    color: #000000;\n    margin-top:10px\n  }\n}\n\nion-card-content{\n  padding:0 20px 24px;\n\n  p{\n    margin-bottom: 20px;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 22px;\n    letter-spacing: 0.7px;\n    color: #92949C;\n  }\n\n  a{\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 22px;\n    letter-spacing: 0.7px;\n    color: #92949C;\n  }\n}\n\nion-card{\n  ion-button{\n    position:absolute;\n    right: 0;\n    top: 0;\n  }\n\n  ion-button::part(native){\n    font-size: 20px;\n    padding: 17px 5px;\n    color: black;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
