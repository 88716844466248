import { Injectable } from '@angular/core';
import { Cliente } from 'src/app/modelos/cliente.model';

@Injectable({
  providedIn: 'root'
})

export class ClienteService{
  public clientes: Array<Cliente> = [];
  
  constructor(
  ) {
  }


}
