import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { NavegacionService } from 'src/app/servicios/navegacion/navegacion.service';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss'],
})
export class NavHeaderComponent implements OnInit {

  @Input() titulo:string;
  @Input() segmSel:string;
  @Input() segmentaciones: Array<{
    slug: string;
    titulo: string;
  }> = [];
  @Input() tituloSel: Array<{
    valor: string;
    texto: string;
  }> = [];
  @Input() volver:boolean;
  @Input() menu: boolean;
  @Input() color_invertido: boolean = false;
  @Output() segmSelExt = new EventEmitter();
  @Output() textSelExt = new EventEmitter();

  customPopoverOptions: any = {
    cssClass: "custom-alert-popover"
  };

  constructor(
    private menuController: MenuController,
    private navegacionService: NavegacionService,
  ) { }

  ngOnInit() { }
  
  onMenu() {
    this.menuController.open("first");
  }

  onVolver() {
    this.navegacionService.back();
  }

  segmentChanged(ev: any) {
    console.log('Segment changed', ev);
  }

  onSegmSel(event) {
    this.segmSelExt.emit(event);
  }
  
  onTituloSel(event) {
    this.textSelExt.emit(event);
  }

}
