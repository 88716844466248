import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'ngx-lottie/lib/symbols';

@Component({
  selector: 'app-modal-timeline-pasos',
  templateUrl: './modal-timeline-pasos.component.html',
  styleUrls: ['./modal-timeline-pasos.component.scss'],
})
export class ModalTimelinePasosComponent implements OnInit {

  options: AnimationOptions = {
    path: '/assets/animaciones/timeline/data.json',
  };
  


  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() { }
  
  animationCreated(animationItem: AnimationItem): void {
    //console.log(animationItem);
  }

  onCloseModal() {
    this.modalController.dismiss();
  }

}
