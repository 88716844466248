// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titulo {
  margin: 10px 0px;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
}

.logo-soty {
  width: 130px;
  margin: 8px auto 0px;
}

ion-button {
  width: 41px;
}
ion-button ion-icon {
  font-size: 25px;
}

ion-segment {
  margin: 0 auto 10px;
  width: 80%;
}

ion-select {
  margin: 0 auto;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
}

.header {
  padding-bottom: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/navegacion/nav-header/nav-header.component.scss"],"names":[],"mappings":"AACA;EACI,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,qBAAA;AAAJ;;AAGA;EACI,YAAA;EACA,oBAAA;AAAJ;;AAGA;EACI,WAAA;AAAJ;AACI;EACI,eAAA;AACR;;AAGA;EACI,mBAAA;EACA,UAAA;AAAJ;;AAGA;EACI,cAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,qBAAA;AAAJ;;AAGA;EACI,mBAAA;AAAJ","sourcesContent":["\n.titulo{\n    margin: 10px 0px;\n    font-weight: 500;\n    font-size: 17px;\n    line-height: 20px;\n    display: flex;\n    align-items: center;\n    text-align: center;\n    letter-spacing: 0.5px;\n}\n\n.logo-soty{\n    width: 130px;\n    margin: 8px  auto 0px;\n}\n\nion-button{\n    width: 41px;\n    ion-icon{\n        font-size: 25px;\n    }\n}\n\nion-segment{\n    margin: 0 auto 10px;\n    width: 80%;\n}\n\nion-select{\n    margin: 0 auto;\n    font-size: 17px;\n    line-height: 20px;\n    display: flex;\n    align-items: center;\n    text-align: center;\n    letter-spacing: 0.5px;\n}\n\n.header{\n    padding-bottom: 2px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
