// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img {
  width: 110.994px;
  height: 110.994px;
  margin: 0 auto;
}
.img img {
  width: 83.009px;
  height: 113.622px;
}

.soperte-titulo {
  text-align: center;
}
.soperte-titulo .titulo {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.texto-contacto {
  margin-left: 35px;
  margin-right: 35px;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 40px;
}

.btn-enviar ion-button {
  display: flex;
  width: 100%;
  height: 56px;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  flex-shrink: 0;
  margin-top: 19.25px;
  border-radius: 7px;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/modales/modal-incidencia-enviada/modal-incidencia-enviada.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;EACA,cAAA;AACJ;AACI;EACI,eAAA;EACA,iBAAA;AACR;;AAIA;EACI,kBAAA;AADJ;AAGI;EACI,WAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AADR;;AAKA;EACI,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAFJ;;AAOI;EACI,aAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,mBAAA;EACA,QAAA;EACA,cAAA;EACA,mBAAA;EACA,kBAAA;AAJR","sourcesContent":[".img{\n    width: 110.994px;\n    height: 110.994px;\n    margin: 0 auto;\n\n    img{\n        width: 83.009px;\n        height: 113.622px;\n    }\n   \n}\n\n.soperte-titulo{\n    text-align: center;\n\n    .titulo{\n        color: #000;\n        font-size: 20px;\n        font-weight: 700;\n        line-height: 24px;\n    }\n}\n\n.texto-contacto{\n    margin-left: 35px;\n    margin-right: 35px;\n    text-align: center;\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 28px;\n    margin-bottom: 40px;\n}\n\n.btn-enviar{\n\n    ion-button{\n        display: flex;\n        width: 100%;\n        height: 56px;\n        flex-direction: column;\n        align-items: center;\n        gap: 3px;\n        flex-shrink: 0;\n        margin-top: 19.25px;\n        border-radius: 7px;\n    }\n   \n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
