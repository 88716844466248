// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-button {
  --background: #ffffff;
  --color: #222428;
  --border-radius: 9px;
  border-radius: 9px;
  width: 100%;
  font-size: large;
  border: 1px solid #575757;
  box-sizing: border-box;
  margin-top: 2em;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/modales/modal-monitorizacion/modal-monitorizacion.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,yBAAA;EACA,sBAAA;EACA,eAAA;AACF","sourcesContent":["ion-button{\n  --background: #ffffff;\n  --color: #222428;\n  --border-radius: 9px;\n  border-radius: 9px;\n  width: 100%;\n  font-size: large;\n  border: 1px solid #575757;\n  box-sizing: border-box;\n  margin-top: 2em;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
