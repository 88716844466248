import { Injectable } from '@angular/core';
import { Camera, CameraResultType, ImageOptions, GalleryImageOptions } from '@capacitor/camera';
import { TranslateService } from '@ngx-translate/core';
import { AlertasService } from '../alertas/alertas.service';

@Injectable({
  providedIn: 'root'
})
  

export class PhotoService {

  options: ImageOptions = {
    allowEditing: false,
    resultType: CameraResultType.Uri,
    width: 1920,
    saveToGallery: true,
  };
  
  optionsPick: GalleryImageOptions = {
    width: 1920,
    limit: 2
  };

  constructor(
    private translate: TranslateService,
    private alertasService: AlertasService,
  ) { }

  public async multiplePicture(limit = 2) {

    //this.optionsPick.limit = limit;
    return new Promise<any>(async (resolve, reject) => {
      Camera.pickImages(this.optionsPick).then(async (images) => {
        console.log(images)
        if (images.photos.length > limit) {
          if (limit > 1) {
            this.alertasService.presentToast(this.translate.instant("generales.error-limite-imgs",{limite: limit}),3000);
          } else {
            this.alertasService.presentToast(this.translate.instant("generales.error-limite-img"),3000);
          }
          resolve([]);
          return;
        }
        let imagesRes = [];
        await Promise.all(images.photos.map(async (image) => {
            let blob = await fetch(image.webPath).then(r => r.blob());
            let fileData: any = {};
            fileData.filename = "file";
            fileData.fileBlob = blob;
            fileData.fileLocal = image.webPath;
            fileData.fileBase64 = await this.blobToBase64(blob);
            imagesRes.push(fileData.fileBase64);
        }));
        resolve(imagesRes);
      }).catch((err) => {
        if (err.message.search("User cancelled") == -1) {
          this.alertasService.presentToast(
            this.translate.instant("generales.error-cargar-img"),
            3000
          );
        }
        reject(err)
      })
    })
  }

  public async takePicture(source = null) {
    if (source) {
      this.options.source = source;
    }
    this.options.resultType = CameraResultType.Base64;
    return new Promise<any>(async (resolve, reject) => {
      Camera.getPhoto(this.options).then(async (image) => {
        let base64 = "data:image/"+image.format+";base64,"+image.base64String;
        resolve(base64);
      }).catch((err) => {
        if (err.message.search("User cancelled") == -1) {
          this.alertasService.presentToast(
            this.translate.instant("generales.error-cargar-img"),
            3000
          );
        }
        reject(err)
      })
    })
  }

  public async takePictureBlob(source = null) {
    if (source) {
      this.options.source = source;
    }
    return new Promise<any>(async (resolve, reject) => {
      Camera.getPhoto(this.options).then(async (image) => {
        let blob = await fetch(image.webPath).then(r => r.blob());
        let fileData: any = {};
        fileData.filename = "file";
        fileData.fileBlob = blob;
        fileData.fileLocal = image.webPath;
        fileData.fileBase64 = await this.blobToBase64(blob);
        resolve(fileData);
      }).catch((err) => {
        if (err.message.search("User cancelled") == -1) {
          this.alertasService.presentToast(
            this.translate.instant("generales.error-cargar-img"),
            3000
          );
        }
        reject(err)
      })
    })
  }

  blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

}
